export function createDynamicImageLoader(watchProp) {
  const dataAttr = watchProp + 'Url';
  return {
    watch: {
      [watchProp]: {
        immediate: true,
        async handler(newVal, oldVal) {
          if (newVal !== oldVal && newVal !== '') {
            if (!newVal.startsWith('/')) {
              try {
                const [imageName, imageExt] = newVal.split('.');
                const path = `@/assets/images/${imageName}.svg?url`;
                // Add the @vite-ignore comment before the dynamic import
                const module = await (/* @vite-ignore */ import(path));
                this[dataAttr] = module?.default || '';
              } catch (error) {
                console.error(error);
                this[dataAttr] = '';
              }
            } else {
              this[dataAttr] = newVal;
            }
          }
        },
      },
    },
  };
}
