<template>
  <div class="home-plans"
       :class="[ {'home-plans-basic' :  planName.toLowerCase() === 'basic'},
                 {'home-plans-border home-plans-pro' : planName.toLowerCase() === 'pro'},
                 {'home-plans-master' : planName.toLowerCase() === 'master'}]">

    <section :class="[planName === $t('plans.pro-title') ? 'home-plans-top' : '']">
      <span v-if="planName === $t('plans.pro-title')">{{ $t('plans.popular-title') }}</span>
    </section>

    <section class="home-plans-packedname"
         :class="[ 'plan-' + planName.toLowerCase() ]">
      <h1>
        <s-icon class="icon" img-class="icon" :icon-image="iconPath" />{{ planName }}
      </h1>

      <span>{{ currencySymbol }}{{ priceInt }}</span>
      <u>{{ priceFractional }}</u>
    </section>

    <section class="home-plans-cta">

      <s-button
        class="s-button-upgrade-fill"
        :class="[ planName.toLowerCase() ]"
        :btn-link="'https://www.seedr.cc/premium?package=' + package">{{ $t('actions.buy-now') }}</s-button>


      <small>{{ $t('plans.subscribe-annually-title') }}</small>
      <small>{{ $t('plans.free-subtitle') }}</small>
      <span>{{ $t('plans.save-title') }} {{ saveValue }}</span>

    </section>

    <section class="home-plans-package-description">
      <p v-html="packageDescriptionMap"/>
    </section>
    <section :class="['home-plans-array s-hide', {'plans-pro' : planName === $t('plans.pro-title')}]">
      <ul>
        <li v-for="(advantage, index) in advantagesArray" :key="index">
          {{ advantage.item }}
        </li>
      </ul>

    </section>
  </div>
</template>


<script>

import SButton from "../components/SButton.vue";
import SIcon from "../components/SIcon.vue";
import {getCurrencySymbol, getPrice} from "#root/helper/Pricing";

import iconBasicImage from "/assets/images/plan_basic_logo_SVG.svg";
import iconProImage from "/assets/images/plan_pro_logo_SVG.svg";
import iconMasterImage from "/assets/images/plan_master_logo_SVG.svg";

export default {
  name: 'HomePlan',
  components: {
    SIcon,
    SButton,
  },
  props: {
    planName: String,
    package: Number
  },
  data () {
    return {
      advantagesArrayBasic: [
        { item: this.$t('home-page.plans.advantages-basic.1')},
        { item: this.$t('home-page.plans.advantages-basic.2')},
        { item: this.$t('home-page.plans.advantages-basic.3')},
      ],
      advantagesArrayPro: [
        { item: this.$t('home-page.plans.advantages-pro.1')},
        { item: this.$t('home-page.plans.advantages-pro.2')},
        { item: this.$t('home-page.plans.advantages-pro.3')},
        { item: this.$t('home-page.plans.advantages-pro.4')},
      ],
      advantagesArrayMaster: [
        { item: this.$t('home-page.plans.advantages-master.1')},
        { item: this.$t('home-page.plans.advantages-master.2')},
        { item: this.$t('home-page.plans.advantages-master.3')},
        { item: this.$t('home-page.plans.advantages-master.4')},
      ],
      planBasicPrice: 0,
      planProPrice: 0,
      planMasterPrice: 0,
      currencySymbol: "$",
      iconBasicImage: iconBasicImage,
      iconProImage: iconProImage,
      iconMasterImage: iconMasterImage,
    }
  },
  computed: {
    saveValue () {
      switch (this.planName) {
        case this.$t('plans.basic-title'):
          return this.currencySymbol + this.planBasicPrice*2
        case this.$t('plans.pro-title'):
          return this.currencySymbol + this.planProPrice*2
        case this.$t('plans.master-title'):
          return this.currencySymbol + this.planMasterPrice*2
        default:
          return ''
      }
    },
    packageDescriptionMap() {
      let description = '';
      switch (this.planName) {
        case this.$t('plans.basic-title'):
          description = this.$t('home-page.plans.advantages-basic-description');
          break;
        case this.$t('plans.pro-title'):
          description = this.$t('home-page.plans.advantages-pro-description');
          break;
        case this.$t('plans.master-title'):
          description = this.$t('home-page.plans.advantages-master-description');
          break;
      }
      return description.replace(/\n/g, '<br>');
    },
    advantagesArray () {
      switch (this.planName) {
        case this.$t('plans.basic-title'):
          return this.advantagesArrayBasic
        case this.$t('plans.pro-title'):
          return this.advantagesArrayPro
        case this.$t('plans.master-title'):
          return this.advantagesArrayMaster
        default:
          return ''
      }
    },
    priceInt () {
      switch (this.planName) {
        case this.$t('plans.basic-title'):
          return parseInt(this.planBasicPrice)
        case this.$t('plans.pro-title'):
          return parseInt(this.planProPrice)
        case this.$t('plans.master-title'):
          return parseInt(this.planMasterPrice)
        default:
          return ''
      }
    },
    priceFractional() {
      let f = '';
      switch (this.planName) {
        case this.$t('plans.basic-title'):
          f = Math.round((this.planBasicPrice % 1) * 100)
          break;
        case this.$t('plans.pro-title'):
          f = Math.round((this.planProPrice % 1) * 100)
          break;
        case this.$t('plans.master-title'):
          f = Math.round((this.planMasterPrice % 1) * 100)
          break;
      }

      return f===0 ? '' : f;
    },
    iconPath () {
      switch (this.planName) {
        case this.$t('plans.basic-title'):
          return this.iconBasicImage
        case this.$t('plans.pro-title'):
          return this.iconProImage
        case this.$t('plans.master-title'):
          return this.iconMasterImage
        default:
          return ''
      }
    },
  },
  methods: {
    // method to safely render HTML content
    safeHtml(html) {
      return { __html: html }
    }
  },
  async created() {
    this.planBasicPrice = await getPrice(24);
    this.planProPrice = await getPrice(12);
    this.planMasterPrice = await getPrice(20);
    this.currencySymbol = getCurrencySymbol();
  },
}
</script>
