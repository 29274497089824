<template>

  <div class="home-feature" :class="{'flip-container': isFlipped}">
    <section class="feature-content-container">
      <div>
        <h1 v-if="title !== ''" v-html="title"></h1>
        <!-- <img :src="require({imageSource})"  alt=""> -->
        <img v-if="imageSource !== ''" :src="imageSource" alt="feature image">
        <h2 v-if="subtitle !== ''" v-html="subtitle"></h2>

        <p v-if="text !== ''" v-html="text"/>

        <s-button v-if="buttonUrl !== undefined" :class="[buttonColor, buttonColor ? 'button-fill' : 'button-secondary']" :btn-link="buttonUrl">{{buttonText ? buttonText : $t('actions.learn-more')}}</s-button>

      </div>
    </section>
    <section class="feature-video-container" :class="{'placeholder' : lottiePath == '' && videoSource == '' && mainImageSource == ''}">
      <img v-if="mainImageSource" :src="mainImageSource" alt="feature main image" :style="imageScale != 1.0 ? 'transform: scale('+imageScale+')' : ''">
      <component
        v-if="lottiePath"
        :is="lottiePath"
        class="feature-video"
        ref="lottieComponentAnimation"
      />
<!--      <LottieSearch v-if="lottiePath === 'LottieSearch'" class="feature-video" ref="lottieSearch"/>-->
<!--<LottieDragAndDrop v-if="lottiePath === 'LottieDragAndDrop'" class="feature-video" ref="lottieDragAndDrop"/>-->
<!--<LottieAuto v-if="lottiePath === 'LottieAuto'" class="feature-video" ref="lottieAuto"/>-->

      <!--       <LottieSearch class="feature-video"/>-->
      <!-- lottie anim included in the div id bm -->
<!--       <Lottie :options="animOpts" class="feature-video"  />-->

<!--       <div id="bm"></div>-->
      <!-- <lottie-animation
       path="../anim/draganddrop/DragAndDrop_1080p.json"
       />   -->

      <video v-if="videoSource !== ''" :poster="videoPlaceholder" autoplay="" loop="" style="" class="feature-video" muted="" playsinline="" data-wf-ignore="true" width="100%">
      </video>
    </section>
  </div>
</template>

<script>
import {defineAsyncComponent} from "vue";

const LottieComponents = {
  LottieSearch: defineAsyncComponent(() => import('../components/LottieSearch.vue')),
  LottieDragAndDrop: defineAsyncComponent(() => import('../components/LottieDragAndDrop.vue')),
  LottieAuto: defineAsyncComponent(() => import('../components/LottieAuto.vue'))
}

import SButton from "../components/SButton.vue";
import {createDynamicImageLoader} from "../helper/DynamicImageLoader";

const dynamicImage = createDynamicImageLoader('imageSource', 'imageSourceUrl')
const dynamicMainImage = createDynamicImageLoader('mainImageSource', 'mainImageSourceUrl')

export default {
  name: 'HomeFeature',
  mixins: [dynamicImage, dynamicMainImage],
  data() {
    return {
      imageSourceUrl: '',
      mainImageSourceUrl: '',
      animOpts: {animationData: null},
      lottieShown: false
    }
  },
  // mounted() {
  //   this.observeLottie();
  // },
  components: {
    SButton,
    ...LottieComponents
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    imageSource: {
      type: String,
      default: ''
    },
    mainImageSource: {
      type: String,
      default: ''
    },
    videoSource: {
      type: String,
      default: ''
    },
    videoPlaceholder: {
      type: String,
      default: ''
    },
    lottiePath: {
      type: String,
      default: ''
    },
    buttonUrl: {
      type: String,
      default: undefined
    },
    buttonText: {
      type: String,
      default: undefined
    },
    buttonColor: {
      type: String,
      default: undefined
    },
    isFlipped: {
      type: Boolean,
      default: false
    },
    imageScale: {
      type: Number,
      default: 1
    }
  },
  computed: {

    // animOpts() {
    //   return {
    //     loop: true,
    //     autoplay: true,
    //     animationData: require(`../anim/draganddrop/DragAndDrop_1080p.json`)
    //   }
    // }
  },
  methods: {

/*    observeLottie() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      };

      const callback = (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.lottieShown = true;
            console.log('lottieShown', this.lottieShown, this.lottiePath);
          } else {
            this.lottieShown = false;
            console.log('lottieShown', this.lottieShown, this.lottiePath);
          }
        });
      };

      if (this.lottiePath) {
        const observer = new IntersectionObserver(callback, options);
        observer.observe(this.$el);
      }
    }*/
  }
};
</script>
