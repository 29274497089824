<template>
  <div class="blog-recent">
    <h2 class="recent-title">Recent Articles</h2>

    <div class="article-grid-wrapper">
    <section class="top-border"/>
      <div class="article-grid article-flex">
        <div
          v-for="article in featuredArticles"
          :key="article.id"
          class="article-card"
        >
          <a :href="`/pulse/articles/${article.id}-${article.slug}`" class="article-image-link">
            <img
              :src="article.imageSizes['thumbnail']?.url ?? article.image"
              :alt="article.title"
              class="article-image"
            >
          </a>

          <div class="article-content">
            <div class="article-meta">
              <a
                v-if="article.category"
                :href="`/pulse/categories/${article.categorySlug}`"
                class="s-badge s-badge-dark category-link"
              >
                {{ article.category }}
              </a>
              <span class="date">{{ formatDate(article.date) }}</span>
            </div>

            <h2 class="article-title">
              <a :href="`/pulse/articles/${article.id}-${article.slug}`">
                {{ article.title }}
              </a>
            </h2>

            <p class="article-description">{{ article.preview }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useData} from "vike-vue/useData";

// get featuredArticles from +data.js
const { featuredArticles } = useData()

const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};
</script>

