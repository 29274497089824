<template>
  <div class="home-testimonials-parent">
    <article class="home-testimonials-child">

      <section class="home-testimonials-title">
<!--        <img src="/assets/images/Home_Testimonial_Asset_L.png" alt="">-->
        <h1>{{ $t('home-page.testimonials-saying') }}<s-icon roundsquare icon-font="s-icon-star" /></h1>
<!--        <img src="/assets/images/Home_Testimonial_Asset_R.png" alt="">-->
      </section>

      <section class="home-testimonials-user-parent">
        <div class="home-testimonials-user" :class="'testimonial-' + (index + 1)" v-for="(testimonial, index) in testimonials" :key="index">
          <div class="top-border"></div>
          <div class="home-testimonials-user-child">
            <h2>{{ testimonial.username }}</h2>
            <span>{{ testimonial.userSource }} {{ $t('home-page.testimonials-user-title') }}</span>
            <hr class="home-testimonials-hr">
            <p>{{ testimonialTextMap(testimonial.username) }}</p>
          </div>
          <img :src="homeTestimonialsSvg" alt="5 starts rating">
        </div>
        <section class="home-testimonials-user-bg"></section>
      </section>
    </article>

    <article class="home-testimonials-child stories">
      <div class="home-testimonials-link">

        <a href="https://twitter.com/seedrcoil">{{ $t('actions.more-stories') }}</a>
      </div>
    </article>

  </div>
</template>

<script>
import SIcon from "../components/SIcon.vue";

import homeTestimonialsSvg from '/assets/images/Home_Testimonial_Asset_SVG.svg?url'

export default {
  name: 'HomeTestimonials',
  components: {SIcon},
  props: {
    msg: String
  },
  data() {
    return {
      userSource: 'Facebook',
      testimonials: [
        {username: "James", userSource: "facebook", },
        {username: "Daniel", userSource: "instagram", },
        {username: "Nancy S.", userSource: "twitter", },
      ],
      homeTestimonialsSvg: homeTestimonialsSvg
    }
  },
  methods: {
    testimonialTextMap(username) {
      switch (username) {
        case "James":
          return this.$t('home-page.testimonials-paragraph-James')
        case "Daniel":
          return this.$t('home-page.testimonials-paragraph-Daniel')
        case "Nancy S.":
          return this.$t('home-page.testimonials-paragraph-Nancy')
        default:
          return ''
      }
    }

  }
  }
</script>

