<template>
  <div class="home-plans-container">

    <section class="home-plans-title-parent">
      <div class="home-plans-title-child">
        <h1>{{ $t('home-page.plans.get-more-title') }}
          <span class="premium">
            <s-icon :icon-image="premiumSvgImg" img-class="icon" />
<!--            <img class="icon" src="/images/plan_premium_logo_SVG.svg?url" alt="">-->
            {{ $t('plans.premium-title') }}</span>
        </h1>
      </div>
      <span class="advantages"><img class="icon" :src="iconUpImg" alt="upgrade Icon">{{ $t('home-page.plans.upgrade-anytime') }}</span>
      <span class="advantages"><img class="icon" :src="iconCancelImg" alt="cancel icon">{{ $t('home-page.plans.cancel-anytime') }}</span>
    </section>


    <section class="home-plans-parent">
      <HomePlan :planName="$t('plans.basic-title')" :package="6"/>

      <div class="home-plans-pro-parent">
        <HomePlan :planName="$t('plans.pro-title')" :package="4"/>
      </div>

      <HomePlan :planName="$t('plans.master-title')" :package="5" />
    </section>

    <h2>{{ $t('home-page.plans.know-more') }} <br class="s-show-pc-xs"> <a href="https://www.seedr.cc/premium"><span>{{ $t('actions.learn-more') }}</span></a></h2>

    <section class="home-try-parent">
      <div class="home-try-child">
        <h1><span class="s-text-blue">{{ $t('home-page.plans.sign-up') }}</span>{{ $t('home-page.plans.try') }}<span class="purple">{{ $t('seedr-title') }}</span>{{ $t('home-page.plans.free') }}</h1>
        <s-button class="button-primary-blue" btn-link="/signup">{{$t('actions.btn-try-free')}}</s-button>
      </div>
      <div class="home-try-child">
        <span><img class="icon" :src="iconCloudImg" alt="icon">Transfer To Our Cloud Storage</span>
        <span><img class="icon" :src="iconDownImg" alt="icon">Stream and Download To Your Device</span>
      </div>
    </section>
  </div>

</template>
<script>

import HomePlan from '../components/HomePlan.vue'
import SButton from "../components/SButton.vue";
import SIcon from "../components/SIcon.vue";

import premiumSvgImg from '/assets/images/plan_premium_logo_SVG.svg?url'
import iconCloudImg from '/assets/images/icon_cloud.svg?url'
import iconDownImg from '/assets/images/icon_down.svg?url'
import iconUpImg from '/assets/images/icon_up.svg?url'
import iconCancelImg from '/assets/images/icon_cancel.svg?url'


// import pricing
import { getPrice, getCurrencySymbol } from '../helper/Pricing.js'

export default {
  name: 'HomePlansContainer',
  components: {
    SIcon,
    SButton,
    HomePlan
  },
  props: {},
  data() {
    return {
      iconCloudImg: iconCloudImg,
      iconDownImg: iconDownImg,
      iconUpImg: iconUpImg,
      iconCancelImg: iconCancelImg,
      premiumSvgImg: premiumSvgImg
    }
  }
}
</script>
