let billingData = {};
let isFetching = false;
let fetchPromise = null;

const fetchBillingData = async () => {
    if (Object.keys(billingData).length === 0 && !isFetching) {
        isFetching = true;
        fetchPromise = fetch('https://www.seedr.cc/billing_plans')
            .then(response => response.json())
            .then(data => {
                billingData = data;
                isFetching = false;
            })
            .catch(() => {
                isFetching = false;
            });
    }

    if (isFetching && fetchPromise) {
        await fetchPromise;
    }
};

const getCurrencySymbol = () => {
    const countryCode = billingData['COUNTRY_CODE'];
    const mapping = {
        'AU': 'AUD',
        'CA': 'CAD',
        'EU': 'EUR',
        'GB': 'GBP',
        'IN': 'INR',
        'JP': 'JPY',
        'US': 'USD',
    };

    const currencySymbols = {
        'AUD': '$',
        'CAD': '$',
        'EUR': '€',
        'GBP': '£',
        'INR': '₹',
        'JPY': '¥',
        'USD': '$',
    };

    if(import.meta.env.SSR) {
        return currencySymbols['USD'];
    } else {
        return currencySymbols[mapping[countryCode] || 'USD'];
    }
};

const getPrice = async (planId) => {
    await fetchBillingData();

    const countryCode = billingData['COUNTRY_CODE'];
    const mapping = {
        'AU': 'AUD',
        'CA': 'CAD',
        'EU': 'EUR',
        'GB': 'GBP',
        'IN': 'INR',
        'JP': 'JPY',
        'US': 'USD',
    };

    const currencyCode = mapping[countryCode] || 'USD';

    if(import.meta.env.SSR) {
        return billingData[planId]['USD'];
    } else {
        return billingData[planId][currencyCode];
    }
};

export { getPrice, getCurrencySymbol };
