<template>
  <section class="home-subscribe-form-parent">
    <div class="home-subscribe-form-block">
      <section class="top-border"/>
      <section class="home-subscribe-form-innerblock blog">
        <h1>{{ $t('home-page.subscribe.read-more') }}</h1>

        <hr class="hr-grey">

        <p>{{ $t('home-page.subscribe.card-paragraph') }}</p>

        <s-button class="button-primary-dark" btn-link="/pulse">{{$t('actions.visit-blog')}}</s-button>
      </section>

<!--      <section class="home-subscribe-form-innerblock subscribe">-->

<!--        <form class="home-subscribe-form" name="Password-form" data-name="Password Form"  @submit.prevent="submitForm">-->
<!--          <input type="email" class="form-input" placeholder="Your Email Address" maxlength="256" name="" required="">-->
<!--          <small>{{ $t('home-page.subscribe.agree') }}-->
<!--            <a href="/termsofuse" target="_blank" rel="noopener">{{ $t('home-page.subscribe.terms') }}</a>-->
<!--            {{ $t('home-page.subscribe.and') }}-->
<!--            <a href="/privacypolicy" target="_blank">{{ $t('home-page.subscribe.policy') }}</a>.-->
<!--          </small>-->

<!--        <input type="submit" class="s-button-link button-secondary input s-button-round" :value="$t('actions.subscribe')">-->

<!--        `</form>-->

<!--      </section>-->
    </div>


  </section>


  <section v-if="pageContext.pageId === '/pages/index'" class="home-subscribe-bg s-hide-pc-xs">
    <p>
    </p>
  </section>
</template>
<script>

import SButton from "../components/SButton.vue";
import Swal from "sweetalert2";

import { usePageContext } from "vike-vue/usePageContext"

export default {
  name: 'SignUpForm',
  components: {
    SButton,
  },
  props: {
    msg: String
  },
  computed: {
    pageContext() {
      return usePageContext()
    }
  },
  methods: {
    async submitForm(event) {
      // Directly construct form data from event.target
      let formData = new FormData(event.target);

      // Post data with fetch
      const response = await fetch('https://www.seedr.cc/v2/signup', {
        method: 'POST',
        body: formData
      });

      const data = await response.json();
      console.log(data);

      if (response.ok) {
        Swal.fire(
          'Congratulations!',
          'You have been submitted to the alpha waitlist.',
          'success'
        )
      } else {
        Swal.fire(
          'Oops!',
          'Something went wrong.',
          'error'
        )
      }
    }
  }
}
</script>
